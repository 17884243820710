import { Theme, createStyles } from '@material-ui/core/styles';

export const styles = (theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            position: 'fixed',
            zIndex: theme.zIndex.drawer,
            maxWidth: '240px',
            marginLeft: '59px',
            marginTop: '-35px',
            '& #hamb': {
                height: '22px',
                width: '35px',
                cursor: 'pointer',
                stroke: theme.palette.primary.dark,
                marginBottom: '24px',
                '&:hover': {
                    stroke: theme.palette.primary.light,
                },
            },
            '& #closeMenuButton': {
                position: 'absolute',
                top: '-10vh',
                right: '-73vw',
                width: '33px',
                cursor: 'pointer',
                zIndex: theme.zIndex.appBar,
                stroke: theme.palette.primary.dark,
                '&:hover': { stroke: theme.palette.primary.main },
            },
            '& #cuencaButton': {
                marginBottom: '33.19px',
                marginTop: '25px',
                maxWidth: '235.5px',
            },
            [theme.breakpoints.down(1350)]: {
                '& #hamb': { marginBottom: '55px' },
                '& #closeMenuButton': {
                    top: '3vh',
                    right: '-75vw',
                    stroke: '#ffffff',
                    zIndex: 1,
                    height: '39px',
                    '&:hover': { stroke: theme.palette.primary.dark },
                },
            },
            [theme.breakpoints.down(1200)]: {
                '& #cuencaButton': {
                    position: 'absolute',
                    top: '3em',
                    right: '5em',
                },
            },
            [theme.breakpoints.down(1000)]: {
                '& #hamb': { marginBottom: '40px' },
            },
            [theme.breakpoints.down(650)]: {
                marginLeft: '35px',
                marginTop: '-20px',
                '& #hamb': { marginBottom: '40px' },
                '& #closeMenuButton': { right: '-80vw' },
                '& #cuencaButton': { top: '3em' },
            },
            [theme.breakpoints.down(501)]: {
                marginLeft: '25px',
                marginTop: '-35px',
                '& #hamb': { width: '30px' },
                '& #cuencaButton': {
                    top: '2.5em',
                    right: '25px',
                },
            },
            [theme.breakpoints.down(350)]: {
                '& #cuencaButton': { right: '15px' },
            },
        },
        above: { zIndex: theme.zIndex.snackbar },
        socialIconsContainer: {
            display: 'flex',
            alignItems: 'flex-start',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '200px',
            [theme.breakpoints.down(1350)]: { height: '320px' },
            [theme.breakpoints.down(650)]: { height: '205px' },
        },
        reca: {
            display: 'flex',
            alignItems: 'flex-start',
            flexDirection: 'column',
            position: 'absolute !important',
            left: '-135px',
            top: '481px',
            width: '310px !important',
            justifyContent: 'space-between',
            transform: 'rotate(270deg)',
            fontSize: '12px',
            lineHeight: '14px',
            letterSpacing: '2px',
            color: 'rgba(61, 73, 117, 0.5)',
            transform: 'rotate(-90deg)',
            [theme.breakpoints.down(1350)]: {
                top: '571px',
                left: '-153px',
                height: '0px',
                width: '314px',
            },
            [theme.breakpoints.down(650)]: {
                top: '431px',
                left: '-153px',
            },
        },
        '@media (max-height: 500px)': {
            root: {
                '& #hamb': {
                    width: '25px',
                    marginBottom: '25px',
                },
                '& #cuencaButton': { top: 0 },
            },
        },
    });
