import { Theme, createStyles } from '@material-ui/core/styles';

export const styles = (theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: '#FFFFFF',
            width: '50vw',
            padding: '50px',
            [theme.breakpoints.down(900)]: { width: '70vw' },
            [theme.breakpoints.down(600)]: {
                width: '75vw',
                padding: '30px',
            },
            [theme.breakpoints.down(570)]: {
                width: '90vw',
                padding: '10px',
            },
        },
        titleContainer: {
            color: '#3D4274',
            margin: 'auto',
            marginBottom: '20px',
            paddingBottom: 0,
            '& .title': {
                fontSize: '40px',
                textAlign: 'center',
            },
            '& .bold': {
                color: theme.palette.primary.light,
            },
            [theme.breakpoints.down(1300)]: {
                '& .title': { fontSize: '30px' },
            },
            [theme.breakpoints.down(1000)]: {
                '& .title': { fontSize: '25px' },
            },
        },
        content: { textAlign: 'center' },
        subtitleContainer: {
            color: '#3E4375',
            marginBottom: '50px',
            '& .subtitle': {
                fontSize: '16px',
            },
            [theme.breakpoints.down(1300)]: {
                '& .subtitle': { fontSize: '13px' },
            },
            [theme.breakpoints.down(1000)]: {
                '& .subtitle': { fontSize: '11px' },
            },
        },
        input: {
            color: '#3D4274',
            marginBottom: '10px',
            width: '60%',
            textAlign: 'center',
            alignItems: 'center',
        },
        buttonContainer: {
            marginTop: '30px',
            '& #cuencaButton': { margin: 'auto' },
        },
        feedback: {
            width: '100%',
            margin: 'auto',
            marginTop: theme.spacing(5),
            position: 'relative',
        },
    });
