// React imports
import React from 'react';

// gatbsy imports
import { Link } from 'gatsby';

// UI - style imports
import { WithStyles, withStyles, useMediaQuery } from '@material-ui/core';
import Box from '@material-ui/core/Box';

// local imports
import { styles } from './TopBar.style';
import Button from 'components/Button';
import cuencaLogo from 'images/logos/cuenca-full-logo.svg';

type TopBarProps = {
    showModal: () => void;
} & typeof defaultProps;

const defaultProps = {
    showModal: () => console.log('Please add function to show modal'),
};

function TopBar(props: TopBarProps & WithStyles<typeof styles>) {
    const { classes, showModal } = props;
    const showButton = !useMediaQuery('(min-width:1350px)');

    return (
        <Box className={classes.root}>
            <Link to="/">
                <img src={cuencaLogo} alt="Logo Cuenca" />
            </Link>

            {showButton && <Button text="Quiero mi cuenta" onClick={showModal} />}
        </Box>
    );
}
TopBar.defaultProps = defaultProps;

export default withStyles(styles)(TopBar);
