import { Theme, createStyles } from '@material-ui/core/styles';

export const styles = (theme: Theme) =>
    createStyles({
        root: {
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            zIndex: theme.zIndex.drawer,
            backgroundColor: '#FFFFFF',
            height: '188px',
            '& img': {
                marginTop: '56px',
                marginLeft: '60px',
                width: '240px',
            },
            '& #cuencaButton': {
                float: 'right',
                marginTop: '56px',
                marginRight: '60px',
            },
            [theme.breakpoints.down(1200)]: { height: '180px' },
            [theme.breakpoints.down(701)]: {
                '& img': {
                    marginLeft: '55px',
                    width: '210px',
                },
                '& #cuencaButton': { marginRight: '55px' },
            },
            [theme.breakpoints.down(650)]: {
                height: '140px',
                '& img': {
                    marginLeft: '35px',
                    marginTop: '35px',
                    width: '150px',
                },
                '& #cuencaButton': {
                    marginRight: '35px',
                    marginTop: '35px',
                },
            },
            [theme.breakpoints.down(501)]: {
                height: '81.14px',
                '& img': {
                    marginLeft: '29px',
                    marginTop: '29px',
                    width: '120px',
                },
                '& #cuencaButton': {
                    marginRight: '28px',
                    marginTop: '28px',
                },
            },
            [theme.breakpoints.down(400)]: {
                '& img': {
                    width: '100px',
                },
            },
        },
        '@media (max-height: 500px)': {
            root: {
                height: '50px',
                '& img': {
                    marginTop: '25px',
                    marginLeft: '25px',
                    width: '70px',
                },
            },
        },
    });
