// React import
import React, { useState, useEffect } from 'react';

// Gatsby imports
import { WindowLocation } from '@reach/router';

// utils imports
import { Helmet } from 'react-helmet';
import queryString from 'query-string';

// UI imports
import Box from '@material-ui/core/Box';
import CookieConsent, { Cookies } from 'react-cookie-consent';

// layout components
import TopBar from 'views/MenuBar/TopBar';
import NavigationBar from 'views/MenuBar/NavigationBar';
import Footer from 'views/Footer';
import PhoneModal from 'components/PhoneModal';

// local imports
import 'fonts/museoSans.css';
import './Layout.style.css';
import { pixel } from 'resources';

type LayoutProps = {
    children: React.ReactNode;
    location: WindowLocation<WindowLocation['state']>;
};

const Layout: React.FC<LayoutProps> = ({ children, location }) => {
    const [phoneModal, setModal] = useState(false);
    const toggleModal = () => setModal(!phoneModal);
    const showReca = location.pathname.includes('contrato');

    useEffect(() => {
        if (typeof document !== 'undefined') {
            if (document.referrer) {
                const source = (queryString.parse(location.search).utm_source as string) || '';
                if (source) setModal(true);
            }
        }
    }, []);

    const showModalOrRedirect = () => {
        if (typeof window !== 'undefined') {
            const userAgent = window.navigator.userAgent;

            pixel.fb_track('InitialCheckout', window);
            pixel.ttq_track('InitiateCheckout', window);
            if (/android/i.test(userAgent)) {
                window.location.replace('https://play.google.com/store/apps/details?id=com.cuenca.cuenca_mobile&hl=es');
            } else if (/iPad|iPhone|iPod/.test(userAgent)) {
                window.location.replace('https://itunes.apple.com/mx/app/cuenca/id1447995379?mt=8');
            } else {
                toggleModal();
            }
        }
    };

    return (
        <>
            <Helmet>
                <meta name="facebook-domain-verification" content="0r6wbhnblnjjyz96xbvb4nihs0bobs" />
            </Helmet>

            <PhoneModal open={phoneModal} handleClose={toggleModal} location={location} />

            <TopBar showModal={showModalOrRedirect} />
            <NavigationBar showModal={showModalOrRedirect} showReca={showReca} />

            <Box id="layout-content">{children}</Box>

            <CookieConsent
                acceptOnScroll
                cookieSecurity
                expires={7}
                acceptOnScrollPercentage={3}
                buttonText="Acepto"
                cookieName="cuenca-facebook-pixel"
                sameSite="none"
                onAccept={() => {
                    Cookies.set('cuenca-google-analytics', true);
                    Cookies.set('cuenca-tiktok-pixel', true);
                    if (typeof window !== 'undefined') {
                        if (typeof window.fbq === 'function') {
                            window.fbq('init', '436171176938956');
                            window.fbq('track', 'PageView');
                        }
                        if (typeof window.ttq === 'object') {
                            window.ttq.track('ViewContent');
                        }
                    }
                }}
                style={{
                    backgroundColor: '#E8E9EE',
                    boxShadow: '0 0 3px 2px #F0F1F4',
                    color: '#3D4274',
                    textAlign: 'center',
                }}
                buttonStyle={{
                    background: 'none',
                    border: 'none',
                    color: '#FF8136',
                    fontSize: '14.5px',
                    fontWeight: 900,
                }}
            >
                Este sitio utiliza cookies para mejorar la experiencia de usuario. Si continua navegando, consideramos
                que acepta su uso.
            </CookieConsent>

            <Footer />
        </>
    );
};

export default Layout;
