// React imports
import React, { useState } from 'react';

// UI - style imports
import { WithStyles, withStyles, useMediaQuery } from '@material-ui/core';
import { motion } from 'framer-motion';
import Box from '@material-ui/core/Box';
import SvgIcon from '@material-ui/core/SvgIcon';
import clsx from 'clsx';

// local imports
import { styles } from './NavigationBar.style';
import Button from 'components/Button';
import SocialIcon from 'components/SocialIcon';
import ToggleMenu from 'views/MenuBar/ToggleMenu';

type NavigatorBarProps = {
    showModal: () => void;
    showReca: false;
} & typeof defaultProps;

const defaultProps = {
    showModal: () => console.log('Please add function to show modal'),
};

const variants = {
    open: {
        opacity: 1,
        x: 0,
        display: 'flex',
        transition: {
            duration: 0.5,
            ease: 'easeIn',
        },
    },
    closed: {
        opacity: 0,
        x: '-100%',
        transition: {
            duration: 0.5,
            ease: 'easeOut',
        },
        transitionEnd: { display: 'none' },
    },
};

function NavigatorBar(props: NavigatorBarProps & WithStyles<typeof styles>) {
    const { classes, showModal, showReca } = props;

    const [showMenu, setShowMenu] = useState(false);
    const showButton = useMediaQuery('(min-width:1350px)');
    const mobileIcons = !useMediaQuery('(min-width:500px)');

    const toggleMenu = () => setShowMenu(!showMenu);
    const size = showButton ? 'medium' : mobileIcons ? 'small' : 'large';
    const specialSize = showButton ? 'small' : mobileIcons ? 'small' : 'medium';

    return (
        <Box className={clsx(classes.root, { [classes.above]: showMenu })}>
            <SvgIcon id="hamb" viewBox="0 0 35 24" onClick={toggleMenu}>
                <g strokeWidth="1" fillRule="evenodd">
                    <g transform="translate(-59.000000, -188.000000)">
                        <g>
                            <g transform="translate(59.398400, 189.566800)">
                                <line x1="34.555" y1="0.5" x2="-3.55271368e-15" y2="0.5" strokeWidth="3.44"></line>
                                <polyline
                                    strokeWidth="3.437"
                                    points="34.555 10.7656 21.3944541 10.7656 14.4456894 10.7656 -3.55271368e-15 10.7656"
                                ></polyline>
                                <line
                                    x1="34.555"
                                    y1="20.6894"
                                    x2="-3.55271368e-15"
                                    y2="20.6894"
                                    strokeWidth="3.437"
                                ></line>
                            </g>
                        </g>
                    </g>
                </g>
            </SvgIcon>

            {showMenu && (
                <SvgIcon id="closeMenuButton" viewBox="0 0 39 39" onClick={toggleMenu}>
                    <g strokeWidth="1" fillRule="evenodd">
                        <g id="closeMenuButton" transform="translate(-1335.000000, -55.000000)" strokeWidth="5">
                            <g transform="translate(1338.100000, 58.000000)">
                                <line x1="0.5" y1="32.5" x2="32.1529956" y2="0.847004351"></line>
                                <line x1="0.673502176" y1="0.673502176" x2="32.3264978" y2="32.3264978"></line>
                            </g>
                        </g>
                    </g>
                </SvgIcon>
            )}

            <motion.div layout initial={false} animate={showMenu ? 'open' : 'closed'} variants={variants}>
                <ToggleMenu />
            </motion.div>

            {showButton && <Button text="Quiero mi cuenta" onClick={showModal} />}

            <Box className={classes.socialIconsContainer}>
                <SocialIcon size={size} social="facebook" link="https://www.facebook.com/cuencamexico" />
                <SocialIcon size={specialSize} social="instagram" link="https://www.instagram.com/cuenca" />
                <SocialIcon size={size} social="twitter" link="https://www.twitter.com/cuenca_mx" />
                <SocialIcon size={size} social="tiktok" link="https://vm.tiktok.com/JdbEhaU" />
                <SocialIcon size={specialSize} social="github" link="https://www.github.com/cuenca-mx" />
            </Box>
            {showReca && (
                <Box className={classes.reca}>
                    <p>RECA: 15717-458-035582/03-01762-0724</p>
                </Box>
            )}
        </Box>
    );
}
NavigatorBar.defaultProps = defaultProps;

export default withStyles(styles)(NavigatorBar);
