import { Theme, createStyles } from '@material-ui/core/styles';

export const styles = (theme: Theme) =>
    createStyles({
        root: {
            fontFamily: 'MuseoSans, sans-serif',
            fontWeight: 300,
            fontSize: '40px',
            color: '#FFFFFF',
            position: 'absolute',
            top: '-4px',
            width: '101vw',
            height: '100vh',
            marginLeft: '-60px',
            marginTop: '-10px',
            backgroundColor: theme.palette.primary.main,
            [theme.breakpoints.down(650)]: { width: '116vw' },
            [theme.breakpoints.down(500)]: {
                '& #menu': { marginLeft: '18%' },
            },
        },
        menu: {
            marginLeft: '30%',
            marginTop: '5%',
            listStyleType: 'none',
            '& li': {
                borderBottom: '1px solid #fff',
                lineHeight: 2,
                letterSpacing: '1.95px',
                marginRight: '40%',
                marginBottom: 0,
                '&:hover': { color: theme.palette.primary.dark },
                [theme.breakpoints.down(650)]: { letterSpacing: '0.5px' },
            },
            '& a': {
                textDecoration: 'none',
                color: '#ffffff',
                '&:hover': { color: theme.palette.primary.dark },
            },
            [theme.breakpoints.down(500)]: { width: '100%' },
        },
        subMenuTitle: { cursor: 'pointer' },
        submenu: {
            marginLeft: '40%',
            listStyleType: 'none',
            fontSize: '25px',
            marginTop: '-20px',
            '& li': {
                padding: '10px 0 10px',
                marginRight: '0%',
                borderBottom: 'none',
                lineHeight: '1.5',
                '&:hover': { color: theme.palette.primary.dark },
            },
            '& #dottedLine': {
                borderBottom: '1px dashed #fff',
                lineHeight: '2',
            },
            '& a': {
                textDecoration: 'none',
                color: '#ffffff',
                '&:hover': { color: theme.palette.primary.dark },
            },
            '& #last': { marginBottom: '30px !important' },
            [theme.breakpoints.down(1350)]: {
                marginTop: '-20px',
                fontSize: '20px',
            },
            [theme.breakpoints.down(650)]: {
                marginTop: '0px',
                marginLeft: '7%',
                fontSize: '17px',
            },
        },
    });
