// React imports
import React, { useCallback, useState } from 'react';

// gatsby imports
import { Link } from 'gatsby';

// UI - style imports
import { WithStyles, withStyles } from '@material-ui/core';
import { motion } from 'framer-motion';
import Box from '@material-ui/core/Box';

// local imports
import { styles } from './ToggleMenu.style';

const variants = {
    open: {
        opacity: 1,
        display: 'flex',
        transition: { ease: 'easeIn', duration: 0.5 },
    },
    closed: {
        opacity: 0,
        transition: { ease: 'easeOut', duration: 0.5 },
        transitionEnd: { display: 'none' },
    },
};

function ToggleMenu(props: WithStyles<typeof styles>) {
    const { classes } = props;
    const [showLegalSubmenu, setShowLegalSubmenu] = useState(false);
    const [showEducationSubmenu, setShowEducationSubmenu] = useState(false);

    const toggleLegal = useCallback(() => {
        setShowEducationSubmenu(false);
        setShowLegalSubmenu(v => !v);
    }, []);
    const toggleEducation = useCallback(() => {
        setShowLegalSubmenu(false);
        setShowEducationSubmenu(v => !v);
    }, []);

    return (
        <Box className={classes.root}>
            <ul className={classes.menu}>
                <li>
                    <Link to="/apartados">Apartados</Link>
                </li>
                <li>
                    <Link to="/comisiones">Comisiones</Link>
                </li>

                <li className={classes.subMenuTitle} onClick={toggleEducation}>
                    <span>Educación Financiera</span>

                    <motion.div
                        layout
                        initial={false}
                        animate={showEducationSubmenu ? 'open' : 'closed'}
                        variants={variants}
                    >
                        <ul className={classes.submenu}>
                            {/* <li id="dottedLine">
                                <Link to="">Economía y Finanzas</Link>
                            </li> */}
                            <li id="dottedLine">
                                <Link to="/educacion-financiera/glosario">Glosario</Link>
                            </li>
                            <li id="dottedLine">
                                <Link to="/educacion-financiera/proteccion-identidad-y-datos">
                                    Protección de Identidad y Datos
                                </Link>
                            </li>
                        </ul>
                    </motion.div>
                </li>

                <li>
                    <a href="https://blog.cuenca.com/">Blog</a>
                </li>

                <li className={classes.subMenuTitle} onClick={toggleLegal}>
                    <span>Legales</span>

                    <motion.div
                        layout
                        initial={false}
                        animate={showLegalSubmenu ? 'open' : 'closed'}
                        variants={variants}
                    >
                        <ul className={classes.submenu}>
                            <li id="dottedLine">
                                <Link to="/aviso-privacidad">Aviso de privacidad</Link>
                            </li>
                            <li id="dottedLine">
                                <Link to="/terminos-y-condiciones">Términos y condiciones</Link>
                            </li>
                            <li id="last">
                                <Link to="/contrato-adhesion">Contrato de adhesión</Link>
                            </li>
                        </ul>
                    </motion.div>
                </li>
            </ul>
        </Box>
    );
}

export default withStyles(styles)(ToggleMenu);
