export const countries = [
    { name: 'México', phoneCode: '52' },
    { name: 'Estados Unidos de América', phoneCode: 1 },
    { name: 'Afghanistan', phoneCode: '93' },
    { name: 'Albania', phoneCode: '355' },
    { name: 'Algeria', phoneCode: '213' },
    { name: 'American Samoa', phoneCode: '1-684' },
    { name: 'Andorra', phoneCode: '376' },
    { name: 'Angola', phoneCode: '244' },
    { name: 'Anguilla', phoneCode: '1-264' },
    { name: 'Antarctica', phoneCode: '672' },
    { name: 'Antigua and Barbuda', phoneCode: '1-268' },
    { name: 'Argentina', phoneCode: '54' },
    { name: 'Armenia', phoneCode: '374' },
    { name: 'Aruba', phoneCode: '297' },
    { name: 'Australia', phoneCode: '61' },
    { name: 'Austria', phoneCode: '43' },
    { name: 'Azerbaijan', phoneCode: '994' },
    { name: 'Bahamas', phoneCode: '1-242' },
    { name: 'Bahrain', phoneCode: '973' },
    { name: 'Bangladesh', phoneCode: '880' },
    { name: 'Barbados', phoneCode: '1-246' },
    { name: 'Belarus', phoneCode: '375' },
    { name: 'Belgium', phoneCode: '32' },
    { name: 'Belize', phoneCode: '501' },
    { name: 'Benin', phoneCode: '229' },
    { name: 'Bermuda', phoneCode: '1-441' },
    { name: 'Bhutan', phoneCode: '975' },
    { name: 'Bolivia', phoneCode: '591' },
    { name: 'Bosnia and Herzegovina', phoneCode: '387' },
    { name: 'Botswana', phoneCode: '267' },
    { name: 'Bouvet Island', phoneCode: '47' },
    { name: 'Brasil', phoneCode: '55' },
    { name: 'British Indian Ocean Territory', phoneCode: '246' },
    { name: 'Brunei Darussalam', phoneCode: '673' },
    { name: 'Bulgaria', phoneCode: '359' },
    { name: 'Burkina Faso', phoneCode: '226' },
    { name: 'Burundi', phoneCode: '257' },
    { name: "C\u00f4te d'Ivoire", phoneCode: '225' },
    { name: 'Cambodia', phoneCode: '855' },
    { name: 'Cameroon', phoneCode: '237' },
    { name: 'Canada', phoneCode: '1' },
    { name: 'Cape Verde', phoneCode: '238' },
    { name: 'Caribbean Netherlands', phoneCode: '599' },
    { name: 'Cayman Islands', phoneCode: '1-345' },
    { name: 'Central African Republic', phoneCode: '236' },
    { name: 'Chad', phoneCode: '235' },
    { name: 'Chile', phoneCode: '56' },
    { name: 'China', phoneCode: '86' },
    { name: 'Christmas Island', phoneCode: '61' },
    { name: 'Cocos (Keeling) Islands', phoneCode: '61' },
    { name: 'Colombia', phoneCode: '57' },
    { name: 'Comoros', phoneCode: '269' },
    { name: 'Congo', phoneCode: '242' },
    { name: 'República Democrática del Congo', phoneCode: '243' },
    { name: 'Cook Islands', phoneCode: '682' },
    { name: 'Costa Rica', phoneCode: '506' },
    { name: 'Croatia', phoneCode: '385' },
    { name: 'Cuba', phoneCode: '53' },
    { name: 'Cura\u00e7ao', phoneCode: '599' },
    { name: 'Cyprus', phoneCode: '357' },
    { name: 'Czech Republic', phoneCode: '420' },
    { name: 'Denmark', phoneCode: '45' },
    { name: 'Djibouti', phoneCode: '253' },
    { name: 'Dominica', phoneCode: '1-767' },
    { name: 'República Dominicana', phoneCode: '1-849' },
    { name: 'Ecuador', phoneCode: '593' },
    { name: 'Egypt', phoneCode: '20' },
    { name: 'El Salvador', phoneCode: '503' },
    { name: 'England', phoneCode: '44' },
    { name: 'Equatorial Guinea', phoneCode: '240' },
    { name: 'Eritrea', phoneCode: '291' },
    { name: 'Estonia', phoneCode: '372' },
    { name: 'Ethiopia', phoneCode: '251' },
    { name: 'Falkland Islands (Malvinas)', phoneCode: '500' },
    { name: 'Faroe Islands', phoneCode: '298' },
    { name: 'Fiji', phoneCode: '679' },
    { name: 'Finland', phoneCode: '358' },
    { name: 'France', phoneCode: '33' },
    { name: 'French Guiana', phoneCode: '594' },
    { name: 'French Polynesia', phoneCode: '689' },
    { name: 'French Southern Territories', phoneCode: '262' },
    { name: 'Gabon', phoneCode: '241' },
    { name: 'Gambia', phoneCode: '220' },
    { name: 'Georgia', phoneCode: '995' },
    { name: 'Germany', phoneCode: '49' },
    { name: 'Ghana', phoneCode: '233' },
    { name: 'Gibraltar', phoneCode: '350' },
    { name: 'Greece', phoneCode: '30' },
    { name: 'Greenland', phoneCode: '299' },
    { name: 'Grenada', phoneCode: '1-473' },
    { name: 'Guadeloupe', phoneCode: '590' },
    { name: 'Guam', phoneCode: '1-671' },
    { name: 'Guatemala', phoneCode: '502' },
    { name: 'Guernsey', phoneCode: '44-1481' },
    { name: 'Guinea', phoneCode: '224' },
    { name: 'Guinea-Bissau', phoneCode: '245' },
    { name: 'Guyana', phoneCode: '592' },
    { name: 'Haiti', phoneCode: '509' },
    { name: 'Islas Heard y McDonald', phoneCode: '672' },
    { name: 'Honduras', phoneCode: '504' },
    { name: 'Hong Kong', phoneCode: '852' },
    { name: 'Hungary', phoneCode: '36' },
    { name: 'Iceland', phoneCode: '354' },
    { name: 'India', phoneCode: '91' },
    { name: 'Indonesia', phoneCode: '62' },
    { name: 'Iran', phoneCode: '98' },
    { name: 'Iraq', phoneCode: '964' },
    { name: 'Ireland', phoneCode: '353' },
    { name: 'Isle of Man', phoneCode: '44-1624' },
    { name: 'Israel', phoneCode: '972' },
    { name: 'Italy', phoneCode: '39' },
    { ame: 'Jamaica', phoneCode: '1-876' },
    { name: 'Japan', phoneCode: '81' },
    { name: 'Jersey', phoneCode: '44' },
    { name: 'Jordan', phoneCode: '962' },
    { name: 'Kazakhstan', phoneCode: '7' },
    { name: 'Kenya', phoneCode: '254' },
    { name: 'Kiribati', phoneCode: '686' },
    { name: 'Kosovo', phoneCode: '383' },
    { name: 'Kuwait', phoneCode: '965' },
    { name: 'Kyrgyzstan', phoneCode: '996' },
    { name: 'Latvia', phoneCode: '371' },
    { name: 'Lebanon', phoneCode: '961' },
    { name: 'Lesotho', phoneCode: '266' },
    { name: 'Liberia', phoneCode: '231' },
    { name: 'Libya', phoneCode: '218' },
    { name: 'Liechtenstein', phoneCode: '423' },
    { name: 'Lithuania', phoneCode: '370' },
    { name: 'Luxembourg', phoneCode: '352' },
    { name: 'Macao', phoneCode: '853' },
    { name: 'Macedonia', phoneCode: '389' },
    { name: 'Madagascar', phoneCode: '261' },
    { name: 'Malawi', phoneCode: '265' },
    { name: 'Malaysia', phoneCode: '60' },
    { name: 'Maldives', phoneCode: '960' },
    { name: 'Mali', phoneCode: '223' },
    { name: 'Malta', phoneCode: '356' },
    { name: 'Marshall Islands', phoneCode: '692' },
    { name: 'Martinique', phoneCode: '596' },
    { name: 'Mauritania', phoneCode: '222' },
    { name: 'Mauritius', phoneCode: '230' },
    { name: 'Mayotte', phoneCode: '262' },
    { name: 'Micronesia', phoneCode: '691' },
    { name: 'Moldova, Republic of', phoneCode: '373' },
    { name: 'Monaco', phoneCode: '377' },
    { name: 'Mongolia', phoneCode: '976' },
    { name: 'Montenegro', phoneCode: '382' },
    { name: 'Montserrat', phoneCode: '1-664' },
    { name: 'Morocco', phoneCode: '212' },
    { name: 'Mozambique', phoneCode: '258' },
    { name: 'Myanmar', phoneCode: '95' },
    { name: 'Namibia', phoneCode: '264' },
    { name: 'Nauru', phoneCode: '674' },
    { name: 'Nepal', phoneCode: '977' },
    { name: 'Netherlands', phoneCode: '31' },
    { name: 'Netherlands Antilles', phoneCode: '599' },
    { name: 'New Caledonia', phoneCode: '687' },
    { name: 'New Zealand', phoneCode: '64' },
    { name: 'Nicaragua', phoneCode: '505' },
    { name: 'Niger', phoneCode: '227' },
    { name: 'Nigeria', phoneCode: '234' },
    { name: 'Niue', phoneCode: '683' },
    { name: 'Norfolk Island', phoneCode: '672' },
    { name: 'North Korea', phoneCode: '850' },
    { name: 'Northern Ireland', phoneCode: '44' },
    { name: 'Northern Mariana Islands', phoneCode: '1-670' },
    { name: 'Norway', phoneCode: '47' },
    { name: 'Oman', phoneCode: '968' },
    { name: 'Pakistan', phoneCode: '92' },
    { name: 'Palau', phoneCode: '680' },
    { name: 'Palestine', phoneCode: '970' },
    { name: 'Panama', phoneCode: '507' },
    { name: 'Papua New Guinea', phoneCode: '675' },
    { name: 'Paraguay', phoneCode: '595' },
    { name: 'Peru', phoneCode: '51' },
    { name: 'Philippines', phoneCode: '63' },
    { name: 'Pitcairn', phoneCode: '64' },
    { name: 'Poland', phoneCode: '48' },
    { name: 'Portugal', phoneCode: '351' },
    { name: 'Puerto Rico', phoneCode: '1-787' },
    { name: 'Qatar', phoneCode: '974' },
    { name: 'Romania', phoneCode: '40' },
    { name: 'Russian Federation', phoneCode: '7' },
    { name: 'Rwanda', phoneCode: '250' },
    { name: 'Réunion', phoneCode: '262' },
    { name: 'Saint Barthélemy', phoneCode: '590' },
    { name: 'Saint Helena', phoneCode: '290' },
    { name: 'Saint Kitts and Nevis', phoneCode: '1-869' },
    { name: 'Saint Lucia', phoneCode: '1-758' },
    { name: 'Saint Martin', phoneCode: '590' },
    { name: 'Saint Pierre and Miquelon', phoneCode: '508' },
    { name: 'San Vicente y las Granadinas', phoneCode: '1-784' },
    { name: 'Samoa', phoneCode: '685' },
    { name: 'San Marino', phoneCode: '378' },
    { name: 'Sao Tome and Principe', phoneCode: '239' },
    { name: 'Saudi Arabia', phoneCode: '966' },
    { name: 'Scotland', phoneCode: '44' },
    { name: 'Senegal', phoneCode: '221' },
    { name: 'Serbia', phoneCode: '381' },
    { name: 'Seychelles', phoneCode: '248' },
    { name: 'Sierra Leone', phoneCode: '232' },
    { name: 'Singapore', phoneCode: '65' },
    { name: 'Sint Maarten (Dutch part)', phoneCode: '1-721' },
    { name: 'Slovakia', phoneCode: '421' },
    { name: 'Slovenia', phoneCode: '386' },
    { name: 'Solomon Islands', phoneCode: '677' },
    { name: 'Somalia', phoneCode: '252' },
    { name: 'South Africa', phoneCode: '27' },
    { name: 'South Korea', phoneCode: '82' },
    { name: 'South Georgia and the South Sandwich Islands', phoneCode: '500' },
    { name: 'South Sudan', phoneCode: '211' },
    { name: 'Spain', phoneCode: '34' },
    { name: 'Sri Lanka', phoneCode: '94' },
    { name: 'Sudan', phoneCode: '249' },
    { name: 'Suriname', phoneCode: '597' },
    { name: 'Svalbard and Jan Mayen', phoneCode: '47' },
    { name: 'Swaziland', phoneCode: '268' },
    { name: 'Sweden', phoneCode: '46' },
    { name: 'Switzerland', phoneCode: '41' },
    { name: 'Syrian Arab Republic', phoneCode: '963' },
    { name: 'Taiwan', phoneCode: '886' },
    { name: 'Tajikistan', phoneCode: '992' },
    { name: 'Tanzania, United Republic of', phoneCode: '255' },
    { name: 'Thailand', phoneCode: '66' },
    { name: 'Timor-Leste', phoneCode: '670' },
    { name: 'Togo', phoneCode: '228' },
    { name: 'Tokelau', phoneCode: '690' },
    { name: 'Tonga', phoneCode: '676' },
    { name: 'Trinidad and Tobago', phoneCode: '1-868' },
    { name: 'Tunisia', phoneCode: '216' },
    { name: 'Turkey', phoneCode: '90' },
    { name: 'Turkmenistan', phoneCode: '993' },
    { name: 'Turks and Caicos Islands', phoneCode: '1-649' },
    { name: 'Tuvalu', phoneCode: '688' },
    { name: 'US Minor Outlying Islands', phoneCode: '1' },
    { name: 'Uganda', phoneCode: '256' },
    { name: 'Ukraine', phoneCode: '380' },
    { name: 'United Arab Emirates', phoneCode: '971' },
    { name: 'United Kingdom', phoneCode: '44' },
    { name: 'Uruguay', phoneCode: '598' },
    { name: 'Uzbekistan', phoneCode: '998' },
    { name: 'Vanuatu', phoneCode: '678' },
    { name: 'Vaticano', phoneCode: '379' },
    { name: 'Venezuela', phoneCode: '58' },
    { name: 'Vietnam', phoneCode: '84' },
    { name: 'Virgin Islands, British', phoneCode: '1-284' },
    { name: 'Virgin Islands, U.S.', phoneCode: '1-340' },
    { name: 'Wales', phoneCode: '44' },
    { name: 'Wallis and Futuna Islands', phoneCode: '681' },
    { name: 'Western Sahara', phoneCode: '212' },
    { name: 'Yemen', phoneCode: '967' },
    { name: 'Zambia', phoneCode: '260' },
    { name: 'Zimbabwe', phoneCode: '263' },
    { name: '\u00c5land Islands', phoneCode: '358' },
];
