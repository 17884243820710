// React imports
import React, { useState } from 'react';

// Gatsby imports
import { WindowLocation } from '@reach/router';

// utils imports
import queryString from 'query-string';

// UI - style imports
import { WithStyles, withStyles } from '@material-ui/core';
import Alert, { Color } from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';

// local imports
import { styles } from './PhoneModal.style';
import { countries } from './utils';
import Button from 'components/Button';
import { celli, pixel } from 'resources';

type PhoneModalProps = {
    open: boolean;
    handleClose: (value: string) => void;
    location: WindowLocation<WindowLocation['state']>;
} & typeof defaultProps;

const defaultProps = {
    open: false,
    handleClose: () => console.log('Please add a close function'),
};

function PhoneModal(props: PhoneModalProps & WithStyles<typeof styles>) {
    const [state, setState] = useState<{ phoneNumber: string; countryCode: string }>({
        phoneNumber: '',
        countryCode: '52',
    });
    const [feedback, setFeedback] = useState<{ message: string; error: boolean }>({
        message: '',
        error: false,
    });

    const handleChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        const name = event.target.name as keyof typeof state;
        setState({
            ...state,
            [name]: event.target.value,
        });
    };

    const handleMessageClose = (event: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') return;

        setFeedback({ message: '', error: false });
    };

    const sendUrlToPhone = async () => {
        const { countryCode, phoneNumber } = state;
        const { location, handleClose } = props;

        if (!phoneNumber) {
            setFeedback({ message: 'Ingresa número válido', error: true });
            return;
        }

        const qString = (queryString.parse(location.search).utm_source as string) || '';
        const referrer = qString.substring(0, 2);
        const token = qString.substring(3);

        const phone = `+${countryCode}${phoneNumber}`;
        const send = await celli.sendCelliLink(phone, referrer, token);

        if (send.success) {
            setFeedback({ message: 'Mensaje enviado correctamente', error: false });

            // Send event to Pixel
            if (typeof window !== 'undefined') {
                pixel.fb_track('CompletedRegistration', window);
                pixel.ttq_track('CompleteRegistration', window);
            }

            setTimeout(() => handleClose(), 5500);
        } else {
            setFeedback({ message: 'Mensaje no enviado, intente de nuevo', error: true });
        }
    };

    const { classes, open, handleClose } = props;

    return (
        <Dialog
            classes={{ paper: classes.root }}
            onClose={handleClose}
            open={open}
            aria-labelledby="whatsapp-number-dialog"
        >
            <DialogTitle className={classes.titleContainer} id="whatsapp-number-dialog">
                <span className="title">
                    Ingresa tu número de <span className="bold">WhatsApp</span>
                </span>
            </DialogTitle>

            <form>
                <DialogContent className={classes.content}>
                    <DialogContentText className={classes.subtitleContainer}>
                        <span className="subtitle">
                            Vamos a enviarte una liga para que puedas descargar la aplicación
                        </span>
                    </DialogContentText>

                    <Select
                        fullWidth
                        required
                        className={classes.input}
                        onChange={handleChange}
                        value={state.countryCode}
                        margin="dense"
                        id="countryCode"
                        name="countryCode"
                        label="País"
                        type="text"
                    >
                        {countries.map(country => (
                            <MenuItem key={`${country.name}${country.phoneCode}`} value={country.phoneCode}>
                                {`${country.name} (+${country.phoneCode})`}
                            </MenuItem>
                        ))}
                    </Select>

                    <TextField
                        autoFocus
                        fullWidth
                        required
                        className={classes.input}
                        onChange={handleChange}
                        value={state.phoneNumber}
                        margin="dense"
                        id="phoneNumber"
                        name="phoneNumber"
                        label="Número"
                        type="number"
                    />

                    <Snackbar
                        className={classes.feedback}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        open={!!feedback.message}
                        autoHideDuration={5000}
                        onClose={handleMessageClose}
                    >
                        <Alert variant="filled" severity={feedback.error ? ('error' as Color) : ('success' as Color)}>
                            {feedback.message}
                        </Alert>
                    </Snackbar>
                </DialogContent>

                <DialogActions className={classes.buttonContainer}>
                    <Button text="Envíame la liga" onClick={sendUrlToPhone} />
                </DialogActions>
            </form>
        </Dialog>
    );
}
PhoneModal.defaultProps = defaultProps;

export default withStyles(styles)(PhoneModal);
